import Cookies from "js-cookie"

const initialAuthState = {
  isLoggedIn: !!Cookies.get("auth_token"),
  user: null
}

const login = draft => {
  draft.isLoggedIn = true
  return draft
}

const logout = draft => {
  draft.isLoggedIn = false
  draft.user = initialAuthState.user
  return draft
}

const addUser = (draft, payload) => {
  draft.user = payload
  return draft
}

const removeUser = (draft, payload) => {
  draft.user = initialAuthState.user
  return draft
}

const AuthReducer = (draft, action) => {
  if(process.env.NEXT_PUBLIC_CURRENT_ENV === "development"){
    console.log("AuthReducer initial draft: ", draft.user)
    console.log("AuthReducer store dispatched: ", action)
  }
  const actions = {
    login: () => {
      console.log(">>>>>> Logging in");
      login(draft)
    },
    logout: () => {
      console.log(">>>>>> Logging out")
      logout(draft)
    },
    addUser: () => {
      addUser(draft, action.payload)
    },
    removeUser: () => {
      removeUser(draft, action.payload)
    }
  }

  draft = actions[action.type]()
}

export { AuthReducer, initialAuthState }
