const initialGlobalState = {
  siteName: "İş Kıbrıs - İşverenler İçin",
  applicationFormResultMessage: null
}

const setApplicationFormResultMessage = (draft, payload) => {
  draft.applicationFormResultMessage = payload
  return draft
}

function GlobalReducer(draft, action) {
  const actions = {
    setApplicationFormResultMessage: () => setApplicationFormResultMessage(draft, action.payload)
  }

  draft = actions[action.type]()
}

export { GlobalReducer, initialGlobalState }
