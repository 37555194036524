import { useEffect } from "react"
import TagManager from "react-gtm-module"
import { useImmerReducer } from "use-immer"
import DispatchContext from "../context/DispatchContext"
import { GlobalReducer, initialGlobalState } from "../store/global.store"
import { AuthReducer, initialAuthState } from "../store/auth.store"

import "../../styles/App.scss"
import AppStateContext from "../context/AppStateContext"

// Store Strapi Global object in context

function MyApp({ Component, pageProps }) {
  const [GlobalState, GlobalDispatcher] = useImmerReducer(GlobalReducer, initialGlobalState)
  const [AuthState, AuthDispatcher] = useImmerReducer(AuthReducer, initialAuthState)

  const StateProviders = { GlobalState, AuthState }

  const DispatchProviders = { GlobalDispatcher, AuthDispatcher }

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? "GTM-N9DN29V" })
  }, [])

  return (
    <AppStateContext.Provider value={{ ...StateProviders }}>
      <DispatchContext.Provider value={{ ...DispatchProviders }}>
        <Component {...pageProps} />
      </DispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

export default MyApp
